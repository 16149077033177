import React, { useState } from 'react'

import slotIcon from '../../images/slot_icon.svg'
import cashImage from '../../images/slots/cash.svg'
import diamondImage from '../../images/slots/diamond.svg'
import giftImage from '../../images/slots/gift.svg'
import heartImage from '../../images/slots/heart.svg'
import sandwatchImage from '../../images/slots/sandwatch.svg'
import trophyImage from '../../images/slots/trophy.svg'
import maticIcon from '../../images/matic_icon.svg'
import coinflipIcon from '../../images/coinflip_icon.svg'
import tailsIcon from '../../images/tails_icon.svg'
import headsIcon from '../../images/heads_icon.svg'

function LastGameInfoTable(prop) {

    const items = [cashImage, diamondImage, giftImage, heartImage, sandwatchImage, trophyImage]
    let setActiveTab = prop.setActiveTab
    let account = prop.account
    let activeTab = prop.activeTab
    let type = prop.type
    let lastGame = convertationDataLastGame(prop.lastGame)
    const [myPnlProGames, setMyPnlProGames] = useState([])

    async function getFullMyLastGames() {
        let games = await fetch(`https://mypnl.pro/recent-games?player=${account}&game-type=${type}`).then(res => res.json()).then(res => res.data).catch(e => [])
        games = convertationDataMyPnl(games)
        setMyPnlProGames(games)
    }

    async function getFullAllLastGames() {
        let games = await fetch(`https://mypnl.pro/recent-games?game-type=${type}`).then(res => res.json()).then(res => res.data).catch(e => [])
        games = convertationDataMyPnl(games)
        setMyPnlProGames(games)
    }

    function convertationDataLastGame(data) {
        if (typeof data !== 'undefined' && data != null) {
            let newLastGame = []
            data[0].map(game => {

                let result_render = null
                if (type === 'SLOT') {
                    result_render = [
                        game[1], game[2], game[3]
                    ]
                }

                if (type === 'COINFLIP') {
                    result_render = [ game[1] ]
                }

                newLastGame.push({
                    result_render: result_render,
                    win_amount: (game[0] / 1e18 !== 0) ? (game[0] / 1e18) : 0
                })
            })
            return newLastGame
        } else {
            return []
        }
    }

    function convertationDataMyPnl(data) {
        if (typeof data !== 'undefined' && data != null) {
            let newLastGame = []
            data.map(game => {
                newLastGame.push({
                    result_render: game.result_render.split(',').splice(1, 3),
                    deposit: game.deposit,
                    block_time: game.block_time,
                    win_amount: game.win_amount,
                })
            })
            return newLastGame
        } else {
            return []
        }
    }

    function renderResultGame(game) {
        if (type === 'SLOT') {
            return (
                <React.Fragment>
                    <img src={items[game.result_render[0]]} />
                    <img src={items[game.result_render[1]]} />
                    <img src={items[game.result_render[2]]} />
                </React.Fragment>
            )
        }

        if (type === 'COINFLIP') {

            function getNameResults(result) {
                return result[0] > 4 ? 'heads' : 'tails'
            }

            function renderCoin(side = 'heads') {
                return (
                    <React.Fragment>
                        {
                            side === 'heads' ?
                                <img src={headsIcon}></img> :
                                <img src={tailsIcon}></img>
                        }
                    </React.Fragment>
                )
            }

            return (
                <React.Fragment>
                    { renderCoin(getNameResults(game.result_render))}
                </React.Fragment>
            )
        }
    }

    function rendertable(data) {
        return (
            <table>
                <thead>
                    <td>Game</td>
                    {
                        activeTab !== 0 && <td>Time</td>
                    }
                    {
                        activeTab !== 0 && <td>Bet</td>
                    }
                    <td>Result</td>
                    <td>Payout</td>
                </thead>
                {
                    data.map(game =>
                        <tr>
                            <td>
                                <div className="blockWithImage">
                                    <img className="gameTypeIcon" src={type === 'SLOT' ? slotIcon : coinflipIcon}></img> <p>{type}</p>
                                </div>
                            </td>
                            {
                                activeTab !== 0 &&
                                <td className="blockWithText">
                                    {(new Date(game.block_time)).toLocaleString()}
                                </td>
                            }
                            {
                                activeTab !== 0 &&
                                <td>
                                    <div className="blockWithImage">
                                        <img className="maticIcon" src={maticIcon}></img>
                                        <p>{game.deposit}</p>
                                    </div>
                                </td>
                            }
                            <td className="resultImages">
                                { renderResultGame(game) }
                            </td>
                            <td>
                                <div className="blockWithImage">
                                    <img className="maticIcon" src={maticIcon}></img>
                                    <p>{game.win_amount}</p>
                                </div>
                            </td>
                        </tr>
                    )
                }
            </table>
        )
    }

    return (
        <div className="lastGameInfoCoinFlip">
            <div className="row paddingTop">
                <div className="col-12">
                    <div className="contentBlock lastGameInfoBlock">
                        <div className="header">
                            <h2>Latest games</h2>
                            <div className="list">
                                <ul>
                                    <li className={activeTab === 0 ? 'active' : ''} onClick={(e) => {
                                        setActiveTab(0)
                                    }}>Now</li>
                                    <li className={activeTab === 1 ? 'active' : ''} onClick={(e) => {
                                        setActiveTab(1)
                                        getFullMyLastGames()
                                    }}>Last</li>
                                    <li className={activeTab === 2 ? 'active' : ''} onClick={(e) => {
                                        setActiveTab(2)
                                        getFullAllLastGames()
                                    }}>All</li>
                                </ul>
                            </div>
                        </div>
                        {
                            rendertable(activeTab === 0 ? lastGame : myPnlProGames)
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default LastGameInfoTable