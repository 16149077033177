import React, { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core"
import "./Pool.css"
import Web3 from "web3"
import { justPoolAddress } from "../../helpers/consts"
import words from "../../helpers/i18n"
import JustPool from "../../contracts/JustPool.json"
import { Area } from '@ant-design/plots';
import { sendEvent } from "../../helpers/analytics"
import { formatNumber } from "../../helpers/consts"

import maticIcon from '../../images/matic_icon.svg'
import liquidityPoolImage from '../../images/liquidityPoolImage.png'
import coins from '../../images/coins.png'

function Pool(prop) {

    const { active, account, library } = useWeb3React()
    const [poolContract, setPoolContract] = useState()
    const [loadPoolContract, setLoadPoolContract] = useState(false)
    const [infoPool, setInfoPool] = useState()
    const [loadInfoPool, setLoadInfoPool] = useState(false)

    const [dataPnl, setDataPnl] = useState([]);
    const [loadDataPnl, setLoadDataPnl] = useState(false)
    const addPopupItem = prop.addPopupItem

    async function getPoolContract() {
        let provider = typeof library !== 'undefined' ? library.currentProvider : null
        const web3 = active ? new Web3(provider) : new Web3('https://rpc-mumbai.matic.today')
        const contract = new web3.eth.Contract(JustPool.abi, justPoolAddress)
        setLoadPoolContract(true)
        setPoolContract(contract)
    }

    async function getPoolInfo() {
        let provider = typeof library !== 'undefined' ? library.currentProvider : null
        const web3 = active ? new Web3(provider) : new Web3('https://rpc-mumbai.matic.today')
        const playerBalanceOfPool = active ? await poolContract.methods.balanceOf(account).call() : 0
        const balancePool = await web3.eth.getBalance(poolContract._address)
        setLoadInfoPool(true)
        setInfoPool({
            playerBalanceOfPool, balancePool
        })
    }

    async function onProvideLiquidity(amount) {
        const key = amount
        if (active && loadPoolContract) {

            let flagCheck = false
            addPopupItem({
                type : 'success',
                title : 'Need moves',
                body : words.signedTransaction,
                time : 10
            })

            await poolContract.methods.provideLiquidity().send({
                from: account,
                value: amount * 1e18
            })
                .on('transactionHash', function (hash) {
                    addPopupItem({
                        type : 'success',
                        title : 'Transaction',
                        body : 'We send you transaction, check if you need ' + hash,
                        time : 10
                    })
                })
                .on('confirmation', function (confirmationNumber, receipt) {
                    /*
                    after 4 confirmations we think transaction is good, and we make refresh lastGame
                    */

                    if (confirmationNumber > 8 && !flagCheck) {
                        getPoolInfo()
                        flagCheck = true
                        addPopupItem({
                            type : 'success',
                            title : 'Success',
                            body : words.success,
                            time : 10
                        })
                    }
                })
                .on('receipt', function (receipt) {
                    console.log(receipt);
                })
                .on('error', function (error, receipt) {
                    addPopupItem({
                        type : 'error',
                        title : 'Something wrong',
                        body : `${words.somethingWrong}:${error.message}`,
                        time : 10
                    })
                });
        } else {
            addPopupItem({
                type : 'warning',
                title : 'Connect wallet',
                body : words.pleaseConnectWallet,
                time : 10
            })
        }
    }

    async function onRemoveLiquididty(amount) {
        const key = amount
        if (active && loadPoolContract) {

            const shares = String(amount * 1e18)
            let flagCheck = false

            addPopupItem({
                type : 'success',
                title : 'Need moves',
                body : words.signedTransaction,
                time : 10
            })

            await poolContract.methods.removeLiquidity(shares).send({
                from: account,
            })
                .on('transactionHash', function (hash) {
                    addPopupItem({
                        type : 'success',
                        title : 'Transaction',
                        body : 'We send you transaction, check if you need ' + hash,
                        time : 10
                    })
                })
                .on('confirmation', function (confirmationNumber, receipt) {
                    /*
                    after 4 confirmations we think transaction is good, and we make refresh lastGame
                    */
                    if (confirmationNumber > 8 && !flagCheck) {
                        getPoolInfo()
                        flagCheck = true
                        addPopupItem({
                            type : 'success',
                            title : 'Success',
                            body : words.success,
                            time : 10
                        })
                    }
                })
                .on('receipt', function (receipt) {
                    console.log(receipt);
                })
                .on('error', function (error, receipt) {
                    addPopupItem({
                        type : 'error',
                        title : 'Something wrong',
                        body : `${words.somethingWrong}:${error.message}`,
                        time : 10
                    })
                });
        } else {
            addPopupItem({
                type : 'warning',
                title : 'Connect wallet',
                body : words.pleaseConnectWallet,
                time : 10
            })
        }
    }

    function provideLiquidity() {
        let amount = prompt('Please enter amount from your provide liquidity', 0.1)
        if (amount != 0 && amount != null) {
            onProvideLiquidity(amount)
            sendEvent({
                category: 'user',
                action: 'click provide liquidity'
            })
        }
    }

    function removeLiquidity() {
        const playerBalanceOfPool = (typeof infoPool !== 'undefined') ? infoPool.playerBalanceOfPool / 1e18 : 0
        let amount = prompt('Please enter amount from your remove liquidity', playerBalanceOfPool)
        if (amount != 0 && amount != null) {
            onRemoveLiquididty(amount)
            sendEvent({
                category: 'user',
                action: 'click remove liquidity'
            })
        }
    }

    async function getPnl(callback) {
        const request = fetch("https://mypnl.pro/pnl", { 'cors-mode': 'no-cors' })
        const data = await request
        const json = await data.json().catch(e => { return { data: [] } })
        callback(json.data)
    }

    useEffect(() => {
        if (!loadPoolContract) {
            getPoolContract()
        }

        if (loadPoolContract && !loadInfoPool) {
            getPoolInfo()
        }

        if (!loadDataPnl) {
            getPnl((json) => {
                setDataPnl(json)
                setLoadDataPnl(true)
            })
        }
    })

    const balancePool = (typeof infoPool !== 'undefined') ? infoPool.balancePool / 1e18 : ''
    const playerBalanceOfPool = (typeof infoPool !== 'undefined') ? infoPool.playerBalanceOfPool / 1e18 : ''
    let config = {}
    if (dataPnl.length !== 0) {
        let data = dataPnl.sort((a, b) => a.block - b.block)
        config = {
            data: data,
            xField: 'block',
            yField: 'share_price',
            xAxis: {
                range: [0, 1],
                tickCount: 5,
            }
        }
    }

    return (
        <div className="poolBLock">

            <div className="row">
                <div className="col-6">
                    <div className="contentBlock liquidityPoolInfo">
                        <div>
                            <h1>Liquidity Pool</h1>
                            <p className="description">Pool for all games, earn with us</p>
                        </div>
                        <div className="balancePool">
                            <img src={maticIcon}></img><p>{formatNumber(balancePool)}</p>
                        </div>
                        <div>
                            <button className="primaryButton">All games</button>
                        </div>
                        <img className="liquidityPoolInfoImage" src={liquidityPoolImage}></img>
                    </div>
                </div>
                <div className="col-6 paddingLeft">
                    <div className="contentBlock earnCashPoolBlock">
                        <div>
                            <h1>Earn with us</h1>
                            <p className="description">If you need you can send your money and earn with us.</p>
                        </div>
                        <div className="earnCashBlockInfo">
                            <div className="info">
                                <p>You share:</p>
                                <div className="balance">
                                    <img src={maticIcon}></img><p>{formatNumber(playerBalanceOfPool)}</p>
                                </div>
                            </div>

                            <div className="info">
                                <p>Your reward:</p>
                                <div className="balance">
                                    <img src={maticIcon}></img><p>{(dataPnl.length !== 0) ? formatNumber(playerBalanceOfPool * dataPnl[dataPnl.length - 1].share_price) : 0}</p>
                                </div>
                            </div>
                        </div>
                        <div className="earnCashBlockButtons">
                            <button onClick={() => provideLiquidity()} className="borderedButton addFunds">Add funds</button>
                            <button onClick={() => removeLiquidity()} className="borderedButton">Withdraw</button>
                        </div>
                        <img className="coinsImage" src={coins}></img>
                    </div>
                </div>
            </div>

            <div className="row paddingTop">
                <div className="col-12">
                    <div className="contentBlock lpTokenPriceBlock">
                        <h1>Price LP Token</h1>
                        <div className="areaLpTokenPrice">
                            {
                                dataPnl.length !== 0 &&
                                <Area {...config} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pool

/*
{ active && 
                <div className="referalProgram">
                    <h2>Referal program</h2>
                    <p>Copy this link and send your friend - <b>{referalLink}</b></p>
                    {
                        dataReferal.length > 0 && <p>Your referals earn for you - <b>{dataReferal[0].amount} MAT</b></p>
                    }
                </div>
            }
            */