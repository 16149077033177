import React, { useState } from "react"
import "./Menu.css"
import { sendEvent } from "../../helpers/analytics"
import { useWeb3React } from "@web3-react/core"

import homeIcon from '../../images/home_icon.svg'
import homeIconChecked from '../../images/home_icon_checked.svg'
import slotIcon from '../../images/slot_icon.svg'
import slotIconChecked from '../../images/slot_icon_checked.svg'
import coinflipIcon from '../../images/coinflip_icon.svg'
import coinflipIconChecked from '../../images/coinflip_icon_checked.svg'
import poolIcon from '../../images/pool_icon.svg'
import poolIconChecked from '../../images/pool_icon_checked.svg'
import logoutIcon from '../../images/logout_icon.svg'
import logoIcon from '../../images/logo.svg'
import referalIcon from '../../images/referal_icon.svg'
import referalIconChecked from '../../images/referal_icon_checked.svg'

function Menu(props) {

    const { active, account, library, connector, activate, deactivate } = useWeb3React()
    const [items, setItems] = useState([
        { key: 1, label: 'Home', checked: true, img: [homeIcon, homeIconChecked] },
        { key: 2, label: 'Slot', checked: false, img: [slotIcon, slotIconChecked] },
        { key: 3, label: 'Coin Flip', checked: false, img: [coinflipIcon, coinflipIconChecked] },
        { key: 4, label: 'Pool', checked: false, img: [poolIcon, poolIconChecked] },
        { key: 5, label: 'Referal', checked: false, img: [referalIcon, referalIconChecked] }
    ])

    function setChecked(key) {
        let newItems = []
        items.map(i => {
            if (i.key === key) props.setActiveGame(i.label)
            i.checked = i.key === key
            newItems.push(i)
        })
        setItems(newItems)
        sendEvent({
            category: 'user',
            action: 'click menu',
            value: key
        })
    }

    async function disconnect() {
        try {
            deactivate()
            sendEvent({
                category: 'user',
                action: 'disconnect wallet'
            })
        } catch (ex) {
            console.log(ex)
        }
    }


    return (
        <div className="menu">
            <div className="logo">
                <img src={logoIcon}></img>
            </div>
            <ul className="list">
                {
                    items.map(i =>
                        <React.Fragment>
                        {
                            (i.key == 4) ? <hr className="menuLine"></hr> : ''
                        }
                        <li onClick={() => setChecked(i.key)} className={i.checked ? 'checked' : ''}>
                            <img src={i.checked ? i.img[1] : i.img[0]} />
                        </li>
                        </React.Fragment>
                    )
                }
            </ul>
            <hr className="menuLine"></hr>
            {
                active ?
                    <div className="logout" onClick={(e) => disconnect()}>
                        <img src={logoutIcon} />
                    </div> : ''
            }
        </div>
    )
}

//https://www.freecodecamp.org/news/fixed-side-and-bottom-navbar-with-css-flexbox/
export default Menu

