import React, { useEffect, useState, useReducer } from "react"

import "./MainPage.css"

import Header from "../Header/Header"
import GameSlot from "../GameSlot/GameSlot"
import Pool from "../Pool/Pool"
import Menu from "../Menu/Menu"
import Home from "../Home/Home"
import GameCoinFlip from "../GameCoinFlip/GameCoinFlip"
import Footer from "../Footer/Footer"
import Referal from "../Referal/Referal"
import Web3 from "web3"
import Popup from "../Popup/Popup"

function MainPage() {

    const [activeGame, setActiveGame] = useState('Home')
    const [referal, setReferal] = useState(false)
    //const [txs, dispatch] = useReducer(taskReducer, [])
    const [popupItems, dispatch] = useReducer(setPopupItemsReducer, [])
    const [openMenu, setOpenMenu] = useState(false)


    function setPopupItemsReducer(items, action) {
        let item = action.item
        let newItems = [...items]



        if (action.type == 'delete') {
            newItems = newItems.filter(e => e.id !== item.id)
        }

        if (action.type == 'add') {
            item.id = items.length
            newItems.push(item)
        }

        return newItems
    }

    function addPopupItem(item) {
        dispatch({
            type: 'add',
            item: item
        })
    }

    function deletePopuItem(item) {
        dispatch({
            type: 'delete',
            item: item
        })
    }

    useEffect(() => {
        const url = new URL(window.location.href)
        const ref = url.searchParams.get('ref')
        const web3 = new Web3()

        if (ref !== null) {
            if (web3.utils.isAddress(ref)) {
                setReferal(ref)
            }
        }
    })
    /* RENDER */
    return (
        <div className="wrapper">
            <Popup items={popupItems} deletePopuItem={deletePopuItem} />
            {
                openMenu &&
                <div className="overlay" onClick={(e) => {
                    setOpenMenu(!openMenu)}
                }>
                </div>
            }
            <div className={`menuContainer ${openMenu ? 'active' : ''}`}>
                <Menu setActiveGame={setActiveGame} />
            </div>
            <div className="mainContainerBlock">
                <div className="mainContainer">
                    <Header
                        addPopupItem={addPopupItem}
                        setOpenMenu={setOpenMenu}
                        openMenu={openMenu}
                    />
                    <div className="gameContainer">
                        <div className="game">
                            {activeGame === 'Home' && <Home />}

                            {activeGame === 'Slot' && <GameSlot addPopupItem={addPopupItem} referal={referal} />}

                            {activeGame === 'Coin Flip' && <GameCoinFlip addPopupItem={addPopupItem} referal={referal} />}

                            {activeGame === 'Pool' && <Pool addPopupItem={addPopupItem} />}

                            {activeGame === 'Referal' && <Referal />}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );

}

export default MainPage;