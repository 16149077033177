import React, { useEffect, useState } from "react"
import { formatNumber } from "../../helpers/consts"
import "./Home.css"

import coinflipIcon from '../../images/coinflip_icon.svg'
import slotIcon from '../../images/slot_icon.svg'
import maticIcon from '../../images/matic_icon.svg'
import womanImage from '../../images/woman.png'
import backgroundMain from '../../images/backgroundMain.png'
import cryptoCoins from '../../images/cryptoCoins.png'

import coinsstack from '../../images/coinsstack.png'
import coinsstack_bg from '../../images/coinsstack_bg.jpg'

import slotImage from '../../images/slotImage.png'
import slotBg from '../../images/slot_bg.jpg'

function Home() {

    const [games, setGames] = useState([])
    const [summary, setSummary] = useState(null)
    const width = window.innerWidth

    async function getLastGames() {
        const games = await fetch('https://mypnl.pro/recent-games').then(res => res.json()).then(res => res.data).catch(e => [])
        setGames(games)
    }

    async function getSummary() {
        const summary = await fetch('https://mypnl.pro/summary').then(res => res.json()).then(res => res).catch(e => null)

        if (summary !== null) {
            let volume = 0
            let totalWin = 0
            for (let i = 0; i < summary.Data.length; i++) {
                let s = summary.Data[i]
                volume += s.total_spent
                totalWin += s.total_won
            }

            setSummary({
                volume, totalWin
            })
        }

    }

    function getGameTypeImage(type) {
        let src = ''
        switch (type) {
            case 'COINFLIP':
                src = coinflipIcon
                break
            case 'SLOT':
                src = slotIcon
                break
            default:
                break;
        }
        return <img className="gameTypeIcon" src={src}></img>
    }

    useEffect(() => {
        getLastGames()
        getSummary()
    }, [])

    return (
        <div className="home">
            <div className="row">
                <div className="col-8 col-m">
                    <div className="contentBlock mainInfoBlock"
                        style={{
                            backgroundImage: `url(${backgroundMain})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover"
                        }}
                    >
                        <div>
                            <h1 className="header">Dragon Games - Smart Casino</h1>
                            <p className="description">Unique games with cash withdrawal and an RNG certificate</p>
                        </div>
                        {
                            summary !== null &&
                            <div className="summaryInfo">
                                <div className="info">
                                    <p className="header">Volume:</p>
                                    <div className="description">
                                        <img src={maticIcon}></img><p>{formatNumber(summary.volume)}</p>
                                    </div>
                                </div>

                                <div className="info">
                                    <p className="header">Wins:</p>
                                    <div className="description">
                                        <img src={maticIcon}></img><p>{formatNumber(summary.totalWin)}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            width > 768 && <img className="womanImage" src={womanImage}></img>
                        }
                    </div>
                </div>
                <div className="col-4 col-m paddingLeft">
                    <div className="contentBlock earnCashBlock">
                        <div>
                            <h1 className="header">Earn cash easy!</h1>
                            <p className="description">If you need you can send your money and earn with us.</p>
                        </div>
                        <div>
                            <button className="borderedButton startEarningButton">Start earning</button>
                        </div>
                        {
                            width > 1024 && <img className="cryptoCoins" src={cryptoCoins}></img>
                        }
                    </div>
                </div>
            </div>

            <div className="row paddingTop">
                <div className="col-6 col-m">
                    <div className="contentBlock gameInfoBlock" style={{
                        backgroundImage: `url(${coinsstack_bg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                    }}>
                        <h1 className="header">Coinflip</h1>
                        <img className="coinsStackImage" src={coinsstack}></img>
                    </div>
                </div>
                <div className="col-6 paddingLeft col-m">
                    <div className="contentBlock gameInfoBlock" style={{
                        backgroundImage: `url(${slotBg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                    }}>
                        <h1 className="header">Slot</h1>
                        <img className="slotImage" src={slotImage}></img>
                    </div>
                </div>
            </div>

            <div className="row paddingTop">
                <div className="col-12">
                    <div className="contentBlock lastGameInfoBlock">
                        <div className="header">
                            <h2>Live bets</h2>
                        </div>
                        <table>
                            <thead>
                                <td>Game</td>
                                {width > 426 && <td>Time</td>}
                                <td>Bet</td>
                                <td>Payout</td>
                            </thead>
                            {
                                games.length > 0 && games.map(game =>
                                    <tr>
                                        <td>
                                            <div className="blockWithImage">
                                                {getGameTypeImage(game.game_type)} {width > 768 && <p>{game.game_type}</p>}
                                            </div>
                                        </td>
                                        {width > 426 &&
                                            <td className="blockWithText">
                                                {(new Date(game.block_time)).toLocaleString()}
                                            </td>
                                        }
                                        <td>
                                            <div className="blockWithImage">
                                                <img className="maticIcon" src={maticIcon}></img>
                                                <p>{game.deposit}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="blockWithImage">
                                                <img className="maticIcon" src={maticIcon}></img>
                                                <p>{game.win_amount}</p>
                                            </div>
                                        </td>
                                    </tr>)
                            }
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Home