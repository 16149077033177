import React from 'react';
import ReactDOM from 'react-dom/client';
//import 'antd/dist/antd.css';
import './index.css'
import MainPage from './components/MainPage/MainPage';
import { Web3ReactProvider } from "@web3-react/core"
import Web3 from "web3"
import ReactGA from 'react-ga4'


function getLibrary(provider) {
    return new Web3(provider)
}

ReactGA.initialize('G-3D4W6KHPG6')
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <div className="main">
        <Web3ReactProvider
            getLibrary={getLibrary}
        >
            <MainPage />
        </Web3ReactProvider>
    </div>
);


