import React from "react"
import "./Footer.css"

function Footer() {

    return (
        <div className="footer">
            <hr></hr>
            <h2><span className="primaryColor">DRAGON</span> GAMES</h2>
            <p>Polyflip is a decentralized betting platform powered by Polygon Network and Chainlink. Unlike traditional casinos that operate in black boxes, Polyflip runs on smart contracts that are fair, transparent and immutable. The platform provides a low-cost and fast gaming experience through the combination of both traditional core game and blockchain mechanics.</p>
            <hr></hr>
            <p className="rigthsReserverd">© 2023 DRAGON GAMES | ALL RIGHTS RESERVED.</p>
        </div>
    )
}

export default Footer