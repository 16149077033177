import React from "react"

function PopupResultGame(prop) {

    const iter = prop.iter
    const amount = prop.amount
    const lastGame = prop.lastGame

    function renderPopupResultGame() {

        let summ = 0
        let betSumm = iter * amount
        let coefficient = 0
        let length = 0

        if (lastGame !== null) {
            length = lastGame[0].length
            lastGame[0].map(game => {
                if ((game[0] / 1e18) !== 0) {
                    summ = summ + (game[0] / 1e18)
                } else {
                    summ = summ - amount
                }
            })
        }

        summ = summ.toFixed(3)

        coefficient = (summ / betSumm).toFixed(3)
        let win = (summ > 0) ? true : false

        return (
            <div className={`resultBlock ${win ? 'win' : 'lose'}`}>
                <div className="header">
                    {win ? 'YOU WIN' : 'YOU LOSE'}
                </div>
                {
                    length > 1 && <p className="count">{length} games result:</p>
                }
                <p className="coefficient">
                    {coefficient}X
                </p>
                <hr></hr>
                <p className="money">{summ} MAT</p>
            </div>
        )


    }


    return (
        <div className="gameResultInfoBlock">
            {renderPopupResultGame()}
        </div>
    )

}

export default PopupResultGame