import React, { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../helpers/connectors"
import Web3 from "web3"
import { chainId } from "../../helpers/consts"
import './Header.css'
import { sendEvent } from "../../helpers/analytics"

import MaticIcon from '../../images/matic_icon.svg'
import menu_icon from '../../images/menu_icon.svg'

function Header(prop) {

    const { active, account, library, connector, activate, deactivate } = useWeb3React()
    const [balance, setBalance] = useState(null)
    const width = window.innerWidth
    const setOpenMenu = prop.setOpenMenu
    const openMenu = prop.openMenu

    const changeNetwork = async () => {
        if (window.ethereum.networkVersion !== chainId[0]) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: Web3.utils.toHex(chainId[0]) }]
                });
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainName: 'Mumbai(MATIC Testnet)',
                                chainId: Web3.utils.toHex(chainId),
                                nativeCurrency: { name: 'MATIC Token', decimals: 18, symbol: 'MATIC Token' },
                                rpcUrls: ['https://rpc-mumbai.matic.today']
                            }
                        ]
                    });
                }
            }
        }
    }

    useEffect(() => {
        if (active) {
            let provider = library.currentProvider
            const web3 = new Web3(provider)
            web3.eth.getBalance(account).then(e => {
                setBalance((e / 1e18).toFixed(3))
            })
        }
    })

    async function connect() {
        try {
            await changeNetwork()
            await activate(injected)
            sendEvent({
                category: 'user',
                action: 'connect wallet'
            })
        } catch (ex) {
            console.log(ex)
        }
    }

    async function disconnect() {
        try {
            deactivate()
            sendEvent({
                category: 'user',
                action: 'disconnect wallet'
            })
        } catch (ex) {
            console.log(ex)
        }
    }

    return (
        <header>
            {
                width > 426 &&
                <div>
                    <h1><span className="primaryColor">DRAGON</span> GAMES</h1>
                </div>
            }
            {
                width <= 426 && 
                <div>
                    <img src={menu_icon}
                        onClick={(e) => setOpenMenu(!openMenu)}
                    ></img>
                </div>
            }
            <div>
                <div>
                    {
                        active &&
                        <div className="userBlock">
                            <div className="transactionsAndUserBalanceBlock">
                                <img src={MaticIcon} />
                                <p className="userBalance">{balance}</p>
                            </div>
                        </div>
                    }
                    <div>
                        {active ?
                            <i></i> :
                            <button onClick={connect} className="primaryButton connectButton">Connect wallet</button>
                        }
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header