import React, { useState, useEffect } from "react"
import "./Referal.css"
import { sendEvent } from "../../helpers/analytics"
import { useWeb3React } from "@web3-react/core"

import maticIcon from '../../images/matic_icon.svg'
import copyIcon from '../../images/copy_icon.svg'
import referalImage from '../../images/referalImage.png'

function Referal(props) {

    const { active, account, library } = useWeb3React()
    const [dataReferal, setDataReferal] = useState([])
    const [loadDataReferal, setLoadDataReferal] = useState(false)

    function formatNumber(num) {
        return Math.round(num * 100) / 100
    }

    async function getDataReferal(callback) {
        const request = fetch(`https://mypnl.pro/referral-payments?referral=${account}`, { 'cors-mode': 'no-cors' })
        const data = await request
        const json = await data.json().catch(e => { return { data: [] } })
        if (json.data == null) json.data = []
        callback(json.data)
    }

    function copyShareLink() {
        const copyText = document.getElementById('link')
        copyText.select()
        copyText.setSelectionRange(0, 99999)
        navigator.clipboard.writeText(copyText.value)

    }

    useEffect(() => {
        if (active && !loadDataReferal) {
            getDataReferal((json) => {
                setDataReferal(json)
                setLoadDataReferal(true)
            })
        }
    }, [])


    const referalLink = (active) ? `${window.location.origin}?ref=${account}` : ''

    return (
        <div className="referalBlock">
            <div className="row">
                <div className="col-12">
                    <div className="contentBlock referalBlockInfo">
                        <div>
                            <h1>Refer players. Earn cash.</h1>
                            <p className="description">Invite players using your link and you’ll get 1% of their earnings - awarded in LP Token - for any game they play on Dragon Games</p>
                        </div>
                        <div className="earnCashBlockInfo">
                            <div className="info">
                                <p>You share:</p>
                                <div className="balance">
                                    <img src={maticIcon}></img><p>{formatNumber(dataReferal.length > 0 ? dataReferal[0].amount : 0)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="shareInformation">
                            <div className="info">
                                <input id="link" style={{width: referalLink.length * 8.8 + 'px'}} value={referalLink}></input>
                                <img src={copyIcon} onClick={(e) => copyShareLink()}></img>
                            </div>
                            <button className="primaryButton shareButton">Share</button>
                        </div>
                        <img className="referalImage" src={referalImage}></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Referal

