const chainId = [80001]

const scanners = [
    'https://mumbai.polygonscan.com/'
]

const justPoolAddress = '0xF0AA37CA2F2E19686858c311bfa5acCb3115e2Dc'
const slotsAddress = '0xB797816d582F0d1a4d73C7f3EB55bDd64586ad16'
const coinFlipAddress = '0x2C74ba4DC784d5fFd049280E9576CEB35c3AeB7F'

function formatNumber(num) {
    return Math.round(num * 100) / 100
}

export {
    chainId,
    justPoolAddress,
    slotsAddress,
    coinFlipAddress,
    scanners,
    formatNumber
}