import ReactGA from 'react-ga4'

async function sendEvent(data) {
    ReactGA.event(data)
    // ReactGA.event({
    //     category: 'Social',
    //     action: 'Rated an App',
    //     value: 3
    // });
}

export { sendEvent }