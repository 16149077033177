import React, { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core"
import "./GameCoinFlip.css"
import CoinFlip from "../../contracts/Coinflip.json"
import { coinFlipAddress, scanners } from "../../helpers/consts"
import Web3 from "web3"
import words from "../../helpers/i18n"
import { sendEvent } from "../../helpers/analytics"
import LastGameInfoTable from "../LastGameInfoTable/LastGameInfoTable"


import xicon from '../../images/xicon.svg'
import tailsIcon from '../../images/tails_icon.svg'
import headsIcon from '../../images/heads_icon.svg'
import coinflipIcon from '../../images/coinflip_icon.svg'
import maticIcon from '../../images/matic_icon.svg'
import PopupResultGame from "../PopupResultGame/PopupResultGame"

/*
орел - true (числа 5-9)
решка - false (числа 0-4)
*/

function GameCoinFlip(prop) {

    const minValue = 0.001
    const { active, account, library } = useWeb3React()
    const [coinFlipContract, setCoinFlipContract] = useState()
    const [loadCoinFlipContract, setLoadCoinFlipContract] = useState()
    const [lastGame, setGame] = useState()
    const [loadedLastGame, setLoad] = useState(false)

    const [amount, setAmount] = useState(0.1)
    const iterations = [1, 2, 5, 10]
    const [iterCoinFlip, setIterCoinFlip] = useState(1)
    const [side, setSide] = useState(true)
    const [activeFaq, setActiveFaq] = useState(false)
    const [viewResultPopup, setViewResultPopup] = useState(false)

    /*
    0 - my last game in blockchain
    1 - my last game past (more 20 games)
    2 - all last games all players
    */
    const [activeTab, setActiveTab] = useState(0)
    const addPopupItem = prop.addPopupItem

    let timerAnimation = null

    async function getCoinFlipContract() {
        let provider = library.currentProvider
        const web3 = new Web3(provider)
        const contract = new web3.eth.Contract(CoinFlip.abi, coinFlipAddress)
        setLoadCoinFlipContract(true)
        setCoinFlipContract(contract)
    }

    async function getLastGameInfo(flagViewResultPopup = false) {
        const games = await coinFlipContract.methods.getLastNPlayerGames(account, iterCoinFlip).call().catch(e => null)
        console.log(games, lastGame)
        if (JSON.stringify(games) !== JSON.stringify(lastGame)) {
            setGame(games)
            setLoad(true)
            if (flagViewResultPopup) {
                setViewResultPopup(true)
                setTimeout(() => {
                    setViewResultPopup(false)
                }, 5000)
            }
            return true
        } else {
            setLoad(true)
            return false
        }
    }

    function getNameResults(result) {
        return result > 4 ? 'heads' : 'tails'
    }

    async function startRoll(amount = minValue) {
        let flagCheck = true
        sendEvent({
            category: 'user',
            action: 'click on roll coniflip'
        })

        if (active && loadCoinFlipContract) {
            addPopupItem({
                type: 'success',
                title: 'Need moves',
                body: words.signedTransaction,
                time: 10
            })

            const _referal = prop.referal
            const flip = (!_referal) ?
                await coinFlipContract.methods.startRoll(iterCoinFlip, side) :
                await coinFlipContract.methods.startRoll_and_setReferal(iterCoinFlip, side, _referal)

            flip.send({
                from: account,
                value: amount * 1e18
            })
                .on('transactionHash', function (hash) {
                    console.log(hash)
                    infiniteAnimationCoin()
                })
                .on('confirmation', function (confirmationNumber, receipt) {
                    if (confirmationNumber > 4 && flagCheck) {
                        getLastGameInfo()
                            .then(result => {
                                if (result === true) {
                                    flagCheck = false
                                    infiniteAnimationCoin(true)
                                    animationCoin()
                                    getLastGameInfo(true)
                                }
                            })
                            .catch(e => console.log(e))
                    }
                })
                .on('receipt', function (receipt) {
                    console.log(receipt)
                })
                .on('error', function (error, receipt) {
                    infiniteAnimationCoin(true)
                    addPopupItem({
                        type: 'error',
                        title: 'Something wrong',
                        body: `${words.somethingWrong}:${error.message}`,
                        time: 10
                    })
                });
        } else {
            addPopupItem({
                type: 'warning',
                title: 'Connect wallet',
                body: words.pleaseConnectWallet,
                time: 10
            })
        }
    }

    function setAmountX2() {
        setAmount(amount * 2)
    }

    function setAmountMod2() {
        setAmount(amount / 2)
    }

    function animationCoin() {
        let outcome = document.querySelector('.outcome')
        if (outcome !== null) {
            outcome.classList.toggle('flip')
            outcome.classList.add('toss')
        }
    }

    function infiniteAnimationCoin(stop = false) {

        let outcome = document.querySelector('.outcome')
        if (outcome !== null) {

            outcome.classList.toggle('flip')
            outcome.classList.add('toss')
            
            if (stop == true) {
                clearInterval(timerAnimation)
                timerAnimation = null
            } else {
                if (timerAnimation == null) {
                    timerAnimation = setInterval(() => {
                        infiniteAnimationCoin()
                    }, 1000)
                }
            }

        }

    }

    function renderCoin(side = 'heads') {
        return (
            <div className="outcome toss flip">
                {
                    side == 'heads' ?
                        <img src={headsIcon}></img> :
                        <img src={tailsIcon}></img>
                }
            </div>
        )
    }

    useEffect(() => {
        if (active && !loadCoinFlipContract) {
            getCoinFlipContract()
        }

        if (active && !loadedLastGame && loadCoinFlipContract) {
            getLastGameInfo()
        }

    }, [active, loadedLastGame, loadCoinFlipContract])

    return (
        <div className="gameCoinFlip">
            <div className="gameCoinFlipContainer">
                <div className="left">
                    <div className="inputNumberBlock">
                        <p>Bet amount</p>
                        <div className="inputNumberBlockContainer">
                            <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
                            <div className="buttonsChangeAmountBlock">
                                <img className="xicon" src={xicon} />
                                <button onClick={() => setAmountMod2()}>1/2</button>
                                <button onClick={() => setAmountX2()}>x2</button>
                            </div>
                        </div>
                    </div>
                    <div className="iterationBlock">
                        <p>Iteration</p>
                        <div className="iterations">
                            {
                                iterations.map(i =>
                                    <div
                                        onClick={(e) => setIterCoinFlip(i)}
                                        className={`iter ${iterCoinFlip == i ? 'active' : 'noactive'}`}>
                                        {i}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="chooseSideBlock">
                        <p>Choose side</p>
                        <div className="sides">
                            <div onClick={e => setSide(true)} className={`side ${side ? 'active' : 'noactive'}`}>
                                <div>
                                    <img src={headsIcon} />
                                    <p>Heads</p>
                                </div>
                            </div>
                            <div onClick={e => setSide(false)} className={`side ${!side ? 'active' : 'noactive'}`}>
                                <div>
                                    <img src={tailsIcon} />
                                    <p>Tails</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="rollButton" type="primary" onClick={() => startRoll(amount, iterCoinFlip)}>Flip coin</button>
                </div>
                <div className="right">
                    {viewResultPopup &&
                        <PopupResultGame
                            iter={iterCoinFlip}
                            amount={amount}
                            lastGame={lastGame}
                        />
                    }
                    {typeof lastGame !== 'undefined' && lastGame != null ? renderCoin(getNameResults(lastGame[0][0][1])) : renderCoin('heads')}
                </div>
            </div>
            <LastGameInfoTable 
                activeTab={activeTab} 
                setActiveTab={setActiveTab} 
                lastGame={lastGame} 
                account={account}
                type={'COINFLIP'}
            />
            <br />
            <div className="gameCoinFlipFaq" onClick={(e) => setActiveFaq(!activeFaq)}>
                <h2>How this work</h2>
                <p className="plus">+</p>
                <div className={activeFaq ? 'active' : 'noactive'}>
                    <p className="gameCoinFlipFaqText">Choose side, start roll and if you choose equal roll, you get <b>x1.95</b></p>
                </div>
            </div>
        </div>
    )
}

export default GameCoinFlip