import React, { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core"
import "./GameSlot.css"
import { slotsAddress, scanners, formatNumber } from "../../helpers/consts"
import Web3 from "web3"
import Slots from "../../contracts/Slots.json"
import words from "../../helpers/i18n"
import { sendEvent } from "../../helpers/analytics"
import LastGameInfoTable from "../LastGameInfoTable/LastGameInfoTable"
import PopupResultGame from "../PopupResultGame/PopupResultGame"

import slotIcon from '../../images/slot_icon.svg'
import xicon from '../../images/xicon.svg'
import cashImage from '../../images/slots/cash.svg'
import diamondImage from '../../images/slots/diamond.svg'
import giftImage from '../../images/slots/gift.svg'
import heartImage from '../../images/slots/heart.svg'
import sandwatchImage from '../../images/slots/sandwatch.svg'
import trophyImage from '../../images/slots/trophy.svg'
import maticIcon from '../../images/matic_icon.svg'


let resultGameForInfiniteAnimation = [0, 1, 2]

function GameSlot(prop) {

    const { active, account, library } = useWeb3React()
    const [slotContract, setSlotContract] = useState()
    const [loadSlotContract, setLoadSlotContract] = useState(false)
    const [lastGame, setGame] = useState(null)
    const [loadedLastGame, setLoad] = useState(false)
    const [iter, setIter] = useState(1)
    const [amount, setAmount] = useState(0.1)
    const items = [cashImage, diamondImage, giftImage, heartImage, sandwatchImage, trophyImage]
    const iterations = [1, 2, 5, 10]
    const [activeFaq, setActiveFaq] = useState(true)
    //const [infiniteAnimation, setInfiniteAnimation] = useState(true)
    const [viewResultPopup, setViewResultPopup] = useState(false)
    /*
    0 - my last game in blockchain
    1 - my last game past (more 20 games)
    2 - all last games all players
    */
    const [activeTab, setActiveTab] = useState(0)

    const minValue = 0.001
    const addPopupItem = prop.addPopupItem

    let timerAnimation = null

    async function getSlotContract() {
        let provider = library.currentProvider
        const web3 = new Web3(provider)
        const contract = new web3.eth.Contract(Slots.abi, slotsAddress)
        setLoadSlotContract(true)
        setSlotContract(contract)
    }

    async function getLastGameInfo(flagViewResultPopup = false) {
        let games = await slotContract.methods.getLastNPlayerGames(account, iter).call().catch(e => null)
        if (JSON.stringify(games) !== JSON.stringify(lastGame)) {
            setGame(games)
            setLoad(true)
            if (flagViewResultPopup) {
                setViewResultPopup(true)
                setTimeout(() => {
                    setViewResultPopup(false)
                }, 5000)
            }
            return true
        } else {
            setLoad(true)
            return false
        }
    }

    async function onRoll(amount = minValue) {
        let flagCheck = true
        sendEvent({
            category: 'user',
            action: 'click on roll slot'
        })

        if (active && loadSlotContract) {
            addPopupItem({
                type: 'success',
                title: 'Need moves',
                body: words.signedTransaction,
                time: 10
            })

            const _referal = prop.referal
            const roll = (!_referal) ?
                await slotContract.methods.startRoll(iter) :
                await slotContract.methods.startRoll_and_setReferal(iter, _referal)

            roll.send({
                from: account,
                value: amount * 1e18
            })
                .on('transactionHash', function (hash) {
                    infiniteAnimationSlotMachine()
                })
                .on('confirmation', function (confirmationNumber, receipt) {
                    if (confirmationNumber > 4 && flagCheck) {
                        getLastGameInfo()
                            .then(result => {
                                if (result === true) {
                                    flagCheck = false
                                    infiniteAnimationSlotMachine(true)
                                    getLastGameInfo(true)
                                }
                            })
                            .catch(e => {
                                infiniteAnimationSlotMachine(true)
                                console.log(e)
                            })
                    }
                })
                .on('receipt', function (receipt) {
                    console.log(receipt)
                })
                .on('error', function (error, receipt) {

                    addPopupItem({
                        type: 'error',
                        title: 'Something wrong',
                        body: `${words.somethingWrong}:${error.message}`,
                        time: 10
                    })
                    
                    infiniteAnimationSlotMachine(true)

                });
        } else {
            addPopupItem({
                type: 'warning',
                title: 'Connect wallet',
                body: words.pleaseConnectWallet,
                time: 10
            })
        }
    }

    function setAmountX2() {
        setAmount(amount * 2)
    }

    function setAmountMod2() {
        setAmount(amount / 2)
    }

    function renderBox(resultGame) {

        const y = (resultGame === 0) ? 0 : resultGame * -16.75

        return (
            <div className="boxes" style={{ transform: `translateY(${y}%)` }}>
                {
                    items.map(i => <div className="box"><img src={i}></img></div>)
                }
            </div>
        )
    }

    function renderSlotMachine(lastGame) {
        if (lastGame === null) lastGame = [[[0, 0, 0, 0]]]
        let index = 0
        return (
            <div className="doors">
                <div className="door">
                    {renderBox(lastGame[0][index][1])}
                </div>

                <div className="door">
                    {renderBox(lastGame[0][index][2])}
                </div>

                <div className="door">
                    {renderBox(lastGame[0][index][3])}
                </div>
            </div>
        )
    }

    async function infiniteAnimationSlotMachine(stop = false) {
        resultGameForInfiniteAnimation[0] = (resultGameForInfiniteAnimation[0] == 5) ? 0 : resultGameForInfiniteAnimation[0] + 1
        resultGameForInfiniteAnimation[1] = (resultGameForInfiniteAnimation[1] == 5) ? 0 : resultGameForInfiniteAnimation[1] + 1
        resultGameForInfiniteAnimation[2] = (resultGameForInfiniteAnimation[2] == 5) ? 0 : resultGameForInfiniteAnimation[2] + 1

        const number1 = resultGameForInfiniteAnimation[0]
        const number2 = resultGameForInfiniteAnimation[1]
        const number3 = resultGameForInfiniteAnimation[2]

        const y1 = (number1 === 0) ? 0 : number1 * -16.75
        const y2 = (number2 === 0) ? 0 : number2 * -16.75
        const y3 = (number3 === 0) ? 0 : number3 * -16.75

        const boxes = document.querySelectorAll('.boxes')

        try {
            boxes[0].style = `transform: translateY(${y1}%)`
            boxes[1].style = `transform: translateY(${y2}%)`
            boxes[2].style = `transform: translateY(${y3}%)`

            if (stop == true) {
                clearInterval(timerAnimation)
                timerAnimation = null
            } else {
                if (timerAnimation == null) {
                    timerAnimation = setInterval(() => {
                        infiniteAnimationSlotMachine()
                    }, 1000)
                }
            }
        } catch (e) {
            clearInterval(timerAnimation)
            timerAnimation = null
        }
    }

    useEffect(() => {
        if (active && !loadSlotContract) {
            getSlotContract()
        }

        if (active && loadSlotContract && !loadedLastGame) {
            getLastGameInfo()
        }

    }, [active, loadSlotContract, loadedLastGame])

    return (
        <div className="gameSlot">
            <div className="gameSlotContainer">
                <div className="left">
                    <div className="inputNumberBlock">
                        <p>Amount</p>
                        <div className="inputNumberBlockContainer">
                            <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
                            <div className="buttonsChangeAmountBlock">
                                <img className="xicon" src={xicon} />
                                <button onClick={() => setAmountMod2()}>1/2</button>
                                <button onClick={() => setAmountX2()}>x2</button>
                            </div>
                        </div>
                    </div>
                    <div className="iterationBlock">
                        <p>Iteration</p>
                        <div className="iterations">
                            {
                                iterations.map(i =>
                                    <div
                                        onClick={(e) => setIter(i)}
                                        className={`iter ${iter == i ? 'active' : 'noactive'}`}>
                                        {i}
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <button className="rollButton" type="primary" onClick={() => onRoll(amount, iter)}>Spin slot</button>
                </div>
                <div className="right">
                    {
                        viewResultPopup &&
                        <PopupResultGame
                            iter={iter}
                            amount={amount}
                            lastGame={lastGame}
                        />
                    }
                    {renderSlotMachine(lastGame)}
                </div>
            </div>
            <LastGameInfoTable
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                lastGame={lastGame}
                account={account}
                type={'SLOT'}
            />
            <br />
            <div className="gameCoinFlipFaq" onClick={(e) => setActiveFaq(!activeFaq)}>
                <h2>How this work</h2>
                <p className="plus">+</p>
                <div className={activeFaq ? 'active' : 'noactive'}>
                    <h1 className="faqHeader">Three in a row</h1>
                    <div className="faqSlotResults">
                        {
                            items.map((item, i) => {
                                if (i == 0 || i == 5) {
                                    return (
                                        <div className="blockFaqSlotResults">
                                            <div className="door">
                                                <img src={items[i]}></img>
                                            </div>
                                            <div className="door">
                                                <img src={items[i]}></img>
                                            </div>
                                            <div className="door">
                                                <img src={items[i]}></img>
                                            </div>
                                            <div className="door noBackground">
                                                <p className="faqCoefficient">X5</p>
                                            </div>
                                        </div>
                                    )
                                }

                                if (i == 4 || i == 3 || i == 2 || i == 1) {
                                    return (
                                        <div className="blockFaqSlotResults">
                                            <div className="door">
                                                <img src={items[i]}></img>
                                            </div>
                                            <div className="door">
                                                <img src={items[i]}></img>
                                            </div>
                                            <div className="door">
                                                <img src={items[i]}></img>
                                            </div>
                                            <div className="door noBackground">
                                                <p className="faqCoefficient">X3</p>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                    <h1 className="faqHeader">Two identical</h1>
                    <div className="faqSlotResults">
                        <div className="blockFaqSlotResults">
                            <div className="door">
                                <img src={items[0]}></img>
                            </div>
                            <div className="door">
                                <img src={items[0]}></img>
                            </div>
                            <div className="door">
                                <img src={items[4]}></img>
                            </div>
                            <div className="door noBackground">
                                <p className="faqCoefficient">X1.7</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameSlot