import React, { useEffect } from "react"

import './Popup.css'

import iconSuccess from '../../images/icon_success.svg'
import iconWarning from '../../images/icon_warning.svg'
import iconError from '../../images/icon_error.svg'
import iconClose from '../../images/icon_close.svg'

function Popup(props) {


    function getImageTypeIcon(item) {
        return (item.type == 'success') ? iconSuccess : (item.type == 'warning') ? iconWarning : (item.type == 'error') ? iconError : iconSuccess
    }

    function closePopup(item) {
        props.deletePopuItem(item)
    }

    useEffect(() => {

        props.items.map(item => {
            if (typeof item.time !== 'undefined') {
                setTimeout(() => {
                    props.deletePopuItem(item)
                }, item.time * 1000)
            }
        })

    })

    return (
        <div className="popups">
            {
                props.items.map(item =>
                    <div className="popup">
                        <div className="header">
                            <div className="titleWithIcon">
                                <img src={getImageTypeIcon(item)}></img>
                                <h1>{item.title}</h1>
                            </div>
                            <img className="closePopupIcon" src={iconClose} onClick={(e) => closePopup(item)}></img>
                        </div>
                        <div className="body">
                            <p>{item.body}</p>
                        </div>
                    </div>
                )
            }
        </div>
    )

}

export default Popup
